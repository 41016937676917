<template>
  <help-page class="help-page" title="general.router-help-answer">
    <el-card shadow="hover">
      <div class="title">{{ answer.title }}</div>
      <hr class="split-line" />
      <div class="content" v-html="answer.content"></div>
    </el-card>
  </help-page>
</template>

<script setup>
import helpPage from '../help-page.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { ref } from 'vue'
const store = useStore()
const route = useRoute()
const title = route.params.title
const type = route.params.type

const answer = ref({})
const initData = async () => {
  const res = await store.dispatch('help/getAnswerList', {
    title,
    type
  })
  answer.value = res
}

initData()
</script>

<style scoped lang="scss">
.help-page {
  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
  }
  .split-line {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #e9e9eb;
    background-color: #e9e9eb;
    height: 1px;
    border: none;
  }
  .content {
    font-size: 14px;
  }
}
</style>
